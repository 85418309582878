<template>
  <v-card>
    <v-card-title
      >{{ $t("downlinks.editorTitle") }}
      <v-icon
        :color="this.hub && this.hub.state == 'Connected' ? 'success' : 'error'"
        >mdi-circle</v-icon
      >
    </v-card-title>
    <v-container>
      <v-row>
        <v-col cols="7">
          <v-text-field
            label="Payload"
            placeholder="Payload"
            filled
            rounded
            dense
            v-model="payload"
            :rules="payloadRequired"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            type="number"
            filled
            rounded
            dense
            placeholder="Port"
            label="Port"
            v-model="port"
          ></v-text-field>
        </v-col>

        <v-col cols="2" class="d-flex justify-center">
          <v-switch v-model="confirmed" label="Confirmed">Confirmed</v-switch>
        </v-col>

        <v-col cols="1">
          <v-btn dense color="primary" fab @click="submit"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-row
        v-for="d in downlinks"
        :key="d.downlinkQueueId"
        :class="`downlinks-row ${d.hasAck ? 'bg-ok' : 'bg-not-ok'}`"
      >
        <v-col cols="4">
          <h4>Name: {{ d.tag.name }}</h4>
          <p>Deveui: {{ d.tag.deveui }}</p>
        </v-col>
        <v-col cols="4">
          <h4>Payload: {{ d.payload }}</h4>
          <p class="mb-0">Port: {{ d.port }}</p>
          <p class="mt-0 mb-0">Confirmed: {{ d.confirmed }}</p>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-center">
          <h4>
            {{ humanDate(d.createdAt) }}
          </h4>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-center">
          <v-btn fab small color="error" @click="openConfirm(d.downlinkQueueId)"
            ><v-icon>mdi-delete-empty</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="confirmDialog" max-width="450">
      <v-card>
        <v-card-title class="headline">
          {{ $t("common.verify") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary lighten-1"
            text
            @click="confirmDialog = false"
            >{{ $t("common.close") }}</v-btn
          >

          <v-btn color="primary" text @click="deleteConfirmed">{{
            $t("common.yes")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";

export default {
  name: "DownlinkEditor",

  props: {
    deveui: {
      default: null,
      require: true,
    },

    loadTag: {
      default: true,
    },
  },

  data() {
    return {
      hub: null,
      tagInfo: {},
      downlinks: [],
      hasQueue: false,
      confirmDialog: false,
      downlinkQueueId: "",

      payload: "",
      port: 1,
      confirmed: false,

      payloadRequired: [
        (v) => !!v || this.$t("validation.required", ["Payload"]),
        (v) =>
          (v && v.length <= 255) || this.$t("validation.max", ["Payload", 255]),
      ],

      portRule: [
        (v) => (!!v && v <= 0) || this.$t("downlinks.validation.port"),
      ],
    };
  },

  computed: {
    ...mapState("tag", ["currentTag"]),
    ...mapState("configuration", ["SO_API_BASE_URL"]),
    ...mapState("users", ["currentUser"]),
  },

  methods: {
    ...mapActions("downlinks", {
      createDownlink: "createDownlink",
      removeDownlink: "removeDownlink",
    }),
    ...mapActions("tag", ["getTagAsync"]),

    openConfirm(downlinkQueueId) {
      this.downlinkQueueId = downlinkQueueId;
      this.confirmDialog = true;
    },

    async deleteConfirmed() {
      await this.removeDownlink(this.downlinkQueueId);
      this.downlinkQueueId = "";
      this.confirmDialog = false;
    },

    async submit() {
      let data = {
        payload: this.payload,
        port: this.port,
        confirmed: this.confirmed,
      };

      await this.createDownlink({ deveui: this.deveui, payload: data });
    },

    deleteDownlink(id) {
      var result = this.downlinks.findIndex((e) => e.downlinkQueueId == id);

      // Remove downlink
      if (result >= 0) this.downlinks.splice(result, 1);
    },

    addDownlink(deveui, downlink) {
      var result = this.downlinks.findIndex(
        (e) => e.downlinkQueueId == downlink.downlinkQueueId
      );
      if (result >= 0) this.downlinks.splice(result, 1);

      this.downlinks.unshift(downlink);
    },
  },

  async created() {
    var url = this.SO_API_BASE_URL;
    url = url + "/downlink-queue";
    this.hub = new HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => this.currentUser.token,
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.hub.on("BroadcastDownlink", this.addDownlink);
    this.hub.on("RemovedDownlink", this.deleteDownlink);
    await this.hub.start();

    this.hub.invoke("JoinGroup", this.deveui);
    this.hub.invoke("GetDownlinks", this.deveui);

    // To suppress warning that the client method is not defined
    // Something wierd with SignalR forces this behaviour.
    this.hub.on("JoinGroup", () => {});

    this.hub.onreconnected(() => {
      this.hub.invoke("JoinGroup", this.deveui);
    });
  },

  beforeDestroy() {
    this.hub.off("BroadcastDownlink", this.addDownlink);
    this.hub.stop();
  },

  async mounted() {
    if (this.loadTag) await this.getTagAsync(this.deveui);
    this.tagInfo = this.currentTag;
  },
};
</script>

<style>
.bg-ok {
  background: #d1e4cc !important;
}

.bg-not-ok {
  background: #ffbfbf !important;
}

.downlinks-row {
  color: black;
  margin-bottom: 0.2rem;
}
</style>